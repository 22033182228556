@import "./variables.scss";

@import url('https://fonts.googleapis.com/css?family=Exo+2');

  .phone-menu-wrapper{
    //Unwrap Main Menu
  .main-menu {
    text-transform: uppercase;
    color: $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: none;
    position: fixed;
    top: 5px;
    right: 15px;
    z-index: 5;
    font-weight: bold;
    height: 55px;
    .main-menu-icon{
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: relative;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('https://res.cloudinary.com/nuhippies/image/upload/v1666958038/Nu%20Hippies/icons/menu-icon_kkqddn.png');
      background-blend-mode: color-burn;
      transition: 0.5s linear;
      cursor: pointer;
      // &:hover{
      //   box-shadow: 0 0 10px 2px $third-color;
      //   background-color: rgb(220, 253, 255);
      //   transform: rotate(-90deg);
      // }
    }
    .label {
      display: inline-block;
      cursor: pointer;
      pointer-events: all;
    }
    .spacer {
      display: inline-block;
      width: 330px;
      margin-left: -222px;
      // margin-top: 253px;
      vertical-align: middle;
      cursor: pointer;
      position: absolute;
      z-index: 0;
    }
    .spacer:before {
      content: "";
      position: absolute;
      border-bottom: 1px solid $text-color;
      height: 1px;
      width: 0%;
      transition: width 0.25s ease;
      transition-delay: 0.7s;
    }
    .item {
      position: relative;
      display: inline-block;
      left: -540px;
      opacity: 0;
      transition: opacity 0.5s ease, left 0.5s ease;
      transition-delay: 0;    
    }
    // .one, .two, .three {
    //   width: 80px;
    //   height: 80px;
    //   transform-style: preserve-3d;
    //   border-radius: 50%;
    //   // transition: all .3s ease;
    //   transition-delay: 1s;
    //   position: relative;
    //   background-position: center center;
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   background-color: #f0c350;
    //   cursor: pointer;
    //   background-blend-mode: color-burn;
    //   margin-bottom: 30px;
    //   z-index: 4;
    //   &:hover {
    //     box-shadow: 0 0 30px 10px $third-color;
    //     background-color: rgb(220, 253, 255);
    //     cursor: pointer;
    //     }
    // }
    // .one{
    //   background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654624433/Nu%20Hippies/icons/second-hand_qitygo.png);
    // }
    // .two{
    //   background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654627203/Nu%20Hippies/icons/nu-hippies-shop_sjaopo.png);
    // }
    // .three{
    //   background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654625675/Nu%20Hippies/icons/forum_edavyj.png);
    // }
    span {
      transition: color 0.5s ease;
    }
    .item:hover span {
      color: $third-color;
    }
  }

.main-menu:hover {
  pointer-events: all;
}
.logo-menu-text{
  font-size: 15px;
  font-weight: bold;
  border: 1px solid $text-color;
}

.main-menu:hover .spacer:before {
  width: 100%;
  transition-delay: 0s;
}
.main-menu:hover .item {
  opacity: 1;
  left: 0px;
}
// .main-menu:hover .main-menu-ol{
//   background: $main-background-color;
//   border-radius: 25px;
// }
.item:nth-child(1) {
  transition-delay: 0.45s;
}
.item:nth-child(2) {
  transition-delay: 0.4s;
}
.item:nth-child(3) {
  transition-delay: 0.35s;
}
.item:nth-child(4) {
  transition-delay: 0.3s;
}
.item:nth-child(5) {
  transition-delay: 0.25s;
}
.item:nth-child(6) {
  transition-delay: 0.2s;
}
.item:nth-child(7) {
  transition-delay: 0.15s;
}
.item:nth-child(8) {
  transition-delay: 0.1s;
}
.item:nth-child(9) {
  transition-delay: 0.05s;
}
.item:nth-child(10) {
  transition-delay: 0s;
}
.main-menu:hover .item:nth-child(1) {
  transition-delay: 0.25s;
}
.main-menu:hover .item:nth-child(2) {
  transition-delay: 0.3s;
}
.main-menu:hover .item:nth-child(3) {
  transition-delay: 0.35s;
}
.main-menu:hover .item:nth-child(4) {
  transition-delay: 0.4s;
}
.main-menu:hover .item:nth-child(5) {
  transition-delay: 0.45s;
}
.main-menu:hover .item:nth-child(6) {
  transition-delay: 0.5s;
}
.main-menu:hover .item:nth-child(7) {
  transition-delay: 0.55s;
}
.main-menu:hover .item:nth-child(8) {
  transition-delay: 0.6s;
}
.main-menu:hover .item:nth-child(9) {
  transition-delay: 0.65s;
}
.main-menu:hover .item:nth-child(10) {
  transition-delay: 0.7s;
}
.main-menu:hover .main-menu-ol{
  background: $main-background-color;
}

.main-menu-ol{
  transition: 0.5s linear;
}

// Unwrap the SubMenus
a{
  text-decoration: none;
  color: $text-color;
  padding: 13px;
  opacity: .8;
  white-space: nowrap;
  font-size: 11px;
}

a:hover{
  opacity: 1;
}

a:before{
  font-family: apple color emoji,segoe ui emoji,notocoloremoji,segoe ui symbol,android emoji,emojisymbols,emojione mozilla;
}

.menu-item:first-of-type a{
  border-radius: 13px 0 0 21px;
}

.menu-item:last-of-type a{
  border-radius: 0 21px 13px 0;
}

.sub-menu .menu-item:first-of-type a{
  border-radius: 13px 21px 0 0;
}

.sub-menu .menu-item:last-of-type a{
  border-radius: 0 0 21px 13px;
}

nav{
  // max-width: 360px;
  // max-height: 480px;
  display: flex;
  align-items: flex-end;
  animation: bounceIn 300ms cubic-bezier(0.175, 0.885, 0.320, 1.275) 1 forwards;
  animation-delay: 500ms;
  transform-style: preserve-3d;
  opacity: 0;
  margin-right: 14px;
  transition: 0.5s ease;
}

@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scaleX(.55) scaleY(.89);
  }
  50%{
    opacity: 1;   
  }
  100%{
    opacity: 1;   
  }
}

ol{
  list-style: none;
  padding: 0 20px 10px 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  position: absolute;
  top: 30px;
}

.sub-menu{
  opacity: 0;
  padding: 0 8px;
  border: 0.2px solid $text-color;
  pointer-events: none;
  position: absolute;
  top: 0px;
  right: 20px;
  transform: skewY(-5deg) scale(.89) rotateX(-5deg) rotateZ(-3deg);
  background: $main-background-color;
}

.menu-item{
  position: relative;
  display: inline-flex;
}

.menu-item:hover .sub-menu{
  pointer-events: all;
  animation: showBounce 300ms cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
  transform-style: preserve-3d;
}

@keyframes showBounce{
  100%{
    opacity: 1;   
    transform: translateX(-21px) skewY(-5deg);
  }
}

.sub-menu li{
  display: flex;
}

.sub-menu a{
  font-size: 11px;
}
.sub-menu .menu-item a{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menu-item:nth-of-type(3) .sub-menu li:nth-of-type(1) a{
  font-size: 27px;
}

.menu-item:nth-of-type(3) .sub-menu li:nth-of-type(2) a{
  font-size: 21px;
}

.menu-item:nth-of-type(3) .sub-menu li:nth-of-type(3) a{
  font-size: 17px;
}

.menu-item a{
  position: relative;
  text-align: center;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-item a:before{
  content: '';
  display: block;
  font-size: 20px;
  transform: rotateZ(-8deg);
  margin-bottom: 5px;
  transition: transform 189ms ease-out;
}

.menu-item a:hover:before{
  animation: hoverEmoji 600ms cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
}

@keyframes hoverEmoji{
  0%{
    transform: scaleX(.89) rotateX(-21deg) rotateZ(-8deg);
  }
  100%{
  transform:  rotateZ(-8deg);
  }
}

.emoji-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-left: 8px;
  span{
    font-size: 11px;
  }
}

nav > ol > .menu-item:nth-of-type(1) > div:before{
  content: '🏠';
}

nav > ol > .menu-item:nth-of-type(2) > a:before{
  content: '✋';
}

nav > ol > .menu-item:nth-of-type(3) > a:before{
  content: '📬';
}

nav > ol > .menu-item:nth-of-type(4) > a:before{
  content: '💬';
}

nav > ol > .menu-item:nth-of-type(5) > div:before{
  content: '👤';
}
//🙋‍♀️
nav > ol > .menu-item:nth-of-type(3) ol li a:before,
nav > ol > .menu-item:nth-of-type(4) ol li a:before{
  content: '💪';
  display: inline-flex;
  font-size: inherit;
  transform: rotateZ(-8deg);
  margin-right: 25px;
  transition: transform 300ms ease-out;
}

nav > ol > li{
  width: 20%;
  margin-right: -4px;
}
//1st item
nav > ol > .menu-item:nth-of-type(1) ol li:nth-of-type(1) a:before{
  content: '🗣';
  margin-right: 10px;
}

nav > ol > .menu-item:nth-of-type(1) ol li:nth-of-type(2) a:before{
  content: '🛒';
  margin-right: 10px;
}

nav > ol > .menu-item:nth-of-type(1) ol li:nth-of-type(3) a:before{
  content: '♻️';
  margin-right: 10px;
}

nav > ol > .menu-item:nth-of-type(1) ol li:nth-of-type(4) a:before{
  content: '🏠';
  margin-right: 10px;
}

//2nd item
nav > ol > .menu-item:nth-of-type(5) ol li:nth-of-type(1) a:before{
  content: '📦';
  margin-right: 10px;
}

nav > ol > .menu-item:nth-of-type(5) ol li:nth-of-type(2) a:before{
  content: '🗂';
  margin-right: 10px;
}

nav > ol > .menu-item:nth-of-type(5) ol li:nth-of-type(3) a:before{
  content: '👤';
  margin-right: 10px;
}

}
