
@font-face {
  font-family: 'Alba';
  src: url("./alba/ALBA____.TTF");
}
$logo-font: 'Alba';
$main-font: "Arial";
// $dark-color: #FF1510;
// $second-color: rgb(250,203,82);
// $third-color: rgb(82,193,199);
// $dark-color: rgb(82,193,199);
$gray-color: #747474;
$navbar-height: 150px;
$pointer-cursor: pointer;
// $pointer-cursor: url('https://res.cloudinary.com/nuhippies/image/upload/v1651164355/Nu%20Hippies/icons/tap_n18gvl.png') 25 25, auto;
$slap-pointer-half-size: 17 17;

$main-background-color: #f3f5fd;;
$dark-color: #474747;
$alternative-dark-color:rgb(80,29,89);
$light-color:rgba(100, 100, 255, .6);
$second-color: #FACB52;
$third-color: #52C1C7;
$fourth-color: #FF1510;
$text-color: #474747;