@import "./variables.scss";

.chat-page{
  display: flex;
  // width: 100vw;
  height: 100%;
  align-items: flex-start;
  justify-content: left;

  button{
    margin-top: 8px;
    width: 100px;
    height: 35px;
    line-height: 35px;
    font-size: 19px;
  }

  .chats-container-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 70vh;
    overflow-y: auto;
    margin-right: 100px;
    margin-top: 20px;
    .active{
      background: white;
    }
  }
  .chat-preview-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 340px;
    height: 70px;
    margin-bottom: 7px;
    color: $text-color;
    text-decoration: none;
    border: 1px solid $third-color;
    border-radius: 25px;
    img{
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
    &:hover{
      background: white;
    }
  }
  .chat-preview-name-message-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 70px;
  }
  .chat-preview-username{
    font-weight: bold;
  }
  .chat-preview-last-message{
    width: 250px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .chats-form-right{
   
  }
  .conversation-input-wrapper{
    width: 560px;
    max-width: 85vw;
    .chat-form{
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 90vw;
      margin-top: 10px;
      img{
        width: 30px;
        height: 30px;
        margin-left: 2px;
        margin-top: 2px;
        border: none;
        &:hover{
          cursor: pointer;
        }
      }
    }
    textarea{
      width: 50%;
      min-width: 220px;
      border-radius: 5px;
      height: 20px;
      line-height: 20px;
      max-height: 80px;
      resize: none;
    }
  }
  .username-picture-onechat{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 15px 9px 0;
    h3{
    }
    img{
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
  .conversation-wrapper-reversed{
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    height: 60vh;
    border-bottom: 1px solid $text-color;
  }
  .conversation-wrapper{
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    max-width: 90vw;

  }
  .chat-received-texts, .chat-user-texts{
    margin: 7px 0;
    width: 370px;
    max-width: 70vw;
    display: flex;
    flex-direction: column;
    word-break: break-all;
  }
  .chat-user-texts{
    align-self: flex-end;
    align-items: flex-end;
    .chat-text-content{
      padding: 6px 14px;
      background: $second-color;
      border-radius: 8px;
      min-width: 79px;
      text-align: center;
      line-height: 20px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .chat-received-texts{
    align-self: flex-start;
    align-items: flex-start;
    .chat-text-content{
      padding: 6px 14px;
      background: $third-color;
      border-radius: 8px;
      min-width: 79px;
      text-align: center;
      line-height: 20px;
      font-size: 16px;
      font-weight: 400;
      color: white;
    }
  }
  .chat-time-stamp{
    font-size: 14px;
    font-weight: 500;
  }
  .no-messages-header{
    text-align: center;
    line-height: 52px;
    min-width: 80vw;
    margin: 0 auto;
    margin-top: 30vh;
  }
}