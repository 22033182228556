@import "./variables.scss";

.flower-content {
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: center;
  width: 150px;
  position: relative;
  cursor: $pointer-cursor;
  z-index: 1;
}
.bright{
  filter: brightness(0.7);
}
.flower {
  display: grid;
  grid-template-columns: repeat(6, 50px);
  grid-template-rows: repeat(6, 50px);
  transform: scale(0.3);
}
.big-petal {
  animation: 10000ms showPetals linear forwards;
  background: linear-gradient(to bottom, rgba(231, 249, 33, 0.536), rgba(204, 193, 99, 0.2));
  opacity: 0;
}
.small-petal {
  animation: 10000ms showPetals linear forwards;
  background-image: linear-gradient(to left, rgba(255, 255, 21, 0.8) 0%, #FF652F 100%);
  opacity: 0;
}
.big-petal1 {
  border-radius: 100% 0;
  box-shadow: 0 2.75em 4.5em rgba(0, 0, 0, 0.2);
  grid-column: 4/-1;
  grid-row: 1/4;
}
.big-petal2 {
  animation-delay: 200ms;
  box-shadow: 2.75em 0 4.5em rgba(0, 0, 0, 0.2);
  border-radius: 0 100%;
  grid-column: 4/-1;
  grid-row: 4/-1;
}
.big-petal3 {
  animation-delay: 400ms;
  border-radius: 100% 0;
  box-shadow: 0 -2.75em 4.5em rgba(0, 0, 0, 0.2);
  grid-column: 1/4;
  grid-row: 4 / -1;
}
.big-petal4 {
  animation-delay: 600ms;
  border-radius: 0 100%;
  box-shadow: -2.75em 0 4.5em rgba(0, 0, 0, 0.2);
  grid-column: 1/4;
  grid-row: 1/4;
}
.small-petal1 {
  animation-delay: 800ms;
  border-radius: 100% 0;
  grid-column: 4/6;
  grid-row: 2/4;
}
.small-petal2 {
  animation-delay: 1000ms;
  border-radius: 100% 0;
  grid-column: 4/6;
  grid-row: 3/5;
  transform: rotate(45deg) translate(0.8rem, -0.8rem);
}
.small-petal3 {
  animation-delay: 1200ms;
  border-radius: 0 100%;
  grid-column: 4/6;
  grid-row: 4/6;
}
.small-petal4 {
  animation-delay: 1400ms;
  border-radius: 100% 0;
  grid-column: 3/5;
  grid-row: 3/5;
  transform: rotate(135deg) translate(3.2rem, -3.2rem);
}
.small-petal5 {
  animation-delay: 1600ms;
  border-radius: 100% 0;
  grid-column: 2/4;
  grid-row: 4/6;
}
.small-petal6 {
  animation-delay: 1800ms;
  border-radius: 100% 0;
  grid-column: 2/4;
  grid-row: 3/5;
  transform: rotate(45deg) translate(-0.6rem, 0.6rem);
}
.small-petal7 {
  animation-delay: 2000ms;
  border-radius: 0 100%;
  grid-column: 2/4;
  grid-row: 2/4;
}
.small-petal8 {
  animation-delay: 2200ms;
  border-radius: 100% 0;
  grid-column: 3/5;
  grid-row: 1/3;
  transform: rotate(135deg) translate(1.7rem, -1.7rem);
}
.flower-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.flower-container.one {
  left: 15px;
  top: 100px;
}
.flower-container.two {
  right: 15px;
  top: 100px;
}
.flowerProductImage {
  height: 220px;
  width: 220px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  background: url("https://res.cloudinary.com/nuhippies/image/upload/v1651088190/Nu%20Hippies/Backgrounds/5F569DF4-0976-4A99-BF3A-6EA369289BE8_4_5005_c_ahg9fh.jpg");
}
.flowerProductImage:hover {
  display: flex;
  border-radius: 0%;
  opacity: 1;
  height: 320px;
  width: 320px;
  transition-duration: 800ms;
  transition-delay: 150ms;
}
@keyframes showPetals {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}