@import "./variables.scss";

.main-forum-page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 100px);
  h1{
    margin-bottom: 35px;
  }
  img{
    width: 300px;
    height: 300px;
  }
}