@import "./variables.scss";

//Main Second Hand Page
.second-hand-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  a{
    text-decoration: none;
    color: $text-color;
  }
  h1{
    text-align: center;
    font-size: 70px;
    color: $text-color;
    font-family: $logo-font;
  }
  .product-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    flex-wrap: wrap;
  }
  .product-wrapper {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
  }
  .product-preview-image {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.4s linear;
  }
  .product-preview-name {
    width: 200px;
    max-height: 80px;
    overflow: hidden;
    padding: 3px 8px;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: center;
  }
  .product-preview-price-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
  .product-preview-price{
    font-size: 22px;
    font-weight: 1000;
  }
}
}

.icon-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 90px;
  color: white;
  max-width: 80vw;
  margin-bottom: 50px;
  .icon-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .icon-description{
    font-weight: 600;
  }
  .first, .second, .third, .fourth, .fifth, .sixth, .seventh, .eighth, .ninth, .tenth, .eleventh, .twelfth{
    width: 70px;
    height: 70px;
    margin: 0 30px;
    border-radius: 20px;
    background-position: center center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-blend-mode: color-burn;
    background-color: #f0c350;
    &:hover {
      box-shadow: 0 0 30px 10px $third-color;
      background-color: rgb(220, 253, 255);
      cursor: pointer;
      }
  }
  .first{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815631/Nu%20Hippies/icons/tshirt_1_f9o6cl.png);
  }
  .second{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815273/Nu%20Hippies/icons/sweatshirt_gsya7o.png);
  }
  .third{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1656630193/Nu%20Hippies/icons/dress_dmz8cr.png);
  }
  .fourth{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1656630586/Nu%20Hippies/icons/pencil-skirt_ehvdzh.png);
  }
  .fifth{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815371/Nu%20Hippies/icons/trousers_pqzr1i.png);
  }
  .sixth{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815419/Nu%20Hippies/icons/sneakers_vpbaka.png);
  }
  .seventh{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815479/Nu%20Hippies/icons/jacket_nrgm5t.png);
  }
  .eighth{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815566/Nu%20Hippies/icons/short_pyvlxb.png);
  }
  .ninth{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815751/Nu%20Hippies/icons/sweater_fygsym.png);
  }
  .tenth{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815810/Nu%20Hippies/icons/sweatshirt_1_ap1zm5.png);
  }
  .eleventh{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815679/Nu%20Hippies/icons/shirt_yzpixi.png);
  }
  .twelfth{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1655815895/Nu%20Hippies/icons/ellipsis_mjp9hg.png);
  }
}

//Loading
.products-loading{
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -250px;
  margin-top: -140px;
  margin-bottom: 100px;
}

//Categorized Items
.categorized{
  // overflow-x: hidden;
  h1{
    font-size: 30px;
    font-family: $logo-font;
  }
  input{
    width: 210px;
    border-radius: 50px;
    height: 22px;
    padding: 5px 12px;
  }
  .distance-input{
    margin-left: 20px;
    font-weight: bold;
    select{
    width: 70px;
    margin-left: 5px;
    }
  }
  .place-distance-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 7px;
  }
.product-container{
  margin-top: 50px;
}
.categories-container{
  display: flex;
  justify-content: center;
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -185px;
  cursor: pointer;
  color: $text-color;
  padding: 10px;
  padding-top: 0px;
  background: $main-background-color;
  border-radius: 20px;
  z-index: 2;
  width: 360px;
  .menu-category{
    .menu-category-header{
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 12px;
      text-align: center;
      padding: 0 30px;
    }
    .menu-item{
      margin-bottom: 14px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;

    }
    .menu-item:hover{
      transform: scale(1.05)
    }
    .menu-item:hover, .menu-category-header:hover{
      color: $second-color;
    }
  }
}
.adress-input-wrapper{
}
}

.adress-input-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.place-wrapper{
  font-weight: bold;
  font-size: 18px;
  padding: 8px 10px;
  margin-top: 5px;
  text-align: center;
  background: $second-color;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
  img {
    width: 25px;
    height: 25px;
  }
}

// Post Item Page
.post-item-page{
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin-bottom: 300px;
  h1{
    position: absolute;
    top: 100px;
  }
  h2{
    margin-top: 30px;
    text-align: center;
  }
  .post-description{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .post-buttons-wrapper{
    display: flex;
    margin-top: 10px;
  }
  .uploaded-images-container{
    max-width: 50vw;
    display: flex;
    flex-wrap: wrap;
  }
  .uploaded-image-wrapper{
    position: relative;
  }
  .close{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 19px;
    height: 19px;
    &:hover{
      cursor: pointer;
    }
  }
  .ad-uploaded-image{
    width: 70px;
    height: 70px;
    margin: 10px;
  }
  .gender-options-wrapper{
    display: flex;
    margin: 30px 0;
  }
  .gender-option{
    padding: 0 15px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 22px;
    border-radius: 5px;
    border: 1px solid transparent;
    &:hover{
      border: 1px solid $text-color;
      border-radius: 5px;
    }
  }
  .selected{
    background: $third-color; 
  }
  .size-input{
    width: 70px;
  }
  .error{
    color: red;
    margin: 4px 0;
  }
  .uploaded-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  .uploaded-item-description{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    max-width: 90vw;
  }
  // .classic-btn{
  //   width: 
  // }
  .post{
    margin: 10px;
  }
}

//Geocoder
.react-geocoder{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.react-geocoder-results{
  margin: 5px 0;
  cursor: pointer;
}
.react-geocoder-item{
  padding: 5px 8px;
  border-radius: 50px;
}
.react-geocoder-item::before {
  content: "• ";
}
.react-geocoder-item:hover{
  background: $second-color;
}

//Single Item Page
.single-item-page{
  margin: 20px 0;
  .single-product-wrapper{
    justify-content: center;
    align-items: center;
  }
  h2{
    font-size: 25px;
  }
  .add-edit-button{
    margin-top: 30px;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;
    &:hover{
      color: $third-color;
    }
  }
  a{
    text-decoration: none;
    color: $text-color;
  }
  .start-chat-wrapper{
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid $text-color;
    padding: 10px;
    border-radius: 20px;
    i{
      opacity: 1;
      font-size: 30px;
      margin-left: 10px;
    }
    &:hover{
      color: $third-color;
    }
  }
  .description-contact-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }
  .single-product-side-info-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2{
      margin-top: 20px;
      color: $fourth-color;
      font-weight: 500;
      font-size: 25px;
    }
  }
  .category-size-wrapper{
    margin-top: 80px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-content: flex-end;
    font-size: 19px;
    font-weight: 500;
    border: 1px solid $text-color;
    border-radius: 20px;
    padding: 15px;
    div{
      margin-bottom: 5px;
    }
  }
  .product-description{
    font-size: 19px;
    line-height: 24px!important;
    align-self: center;
    div{
      background: white;
      padding: 8px 18px;
      text-align: center;
    }
  }
  .single-item-contact{
    margin-top: 40px;
    font-size: 19px;
    div{
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
  .date-single-item{
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
}