@import "./variables.scss";

// .navbar-part{
//   position: fixed;
//   width: 100vw;
//   // box-shadow: 5px -29px 108px 16px  $second-color;
//   z-index: 7;
// }

.navbar {
  width: 100vw;
  height: 140px;
  font-size: 30px;
  // margin-bottom: 15px;
  display: flex;
  z-index: 4;
  //Unwrap logo menu | custom from original version
  .menu{
    left: calc(50% - 20px);
    margin-left: -40px;
  }
  a{
    text-decoration: none;
  }
  a .active{
    color: $third-color;
  }

.products-navbar{
  display: flex;
  position: absolute;
  font-family: $main-font!important;
  font-weight: 300;
  font-size: 17px;
  padding-top: 14px;
  left: 0px;
  .products-menu-close{
    display: flex;
    align-self: flex-start;
    position: absolute;
    cursor: $pointer-cursor;
    img{
      width: 50px;
      height: 50px;
    &:hover{
      transform: scale(1.1);
    }
  }
  }
  .products-menu-close:hover{
    color: $third-color;
  }
  .products-navbar-icon{
    position: absolute;
    left: 25px;
    top: 15px;
    background: url('https://res.cloudinary.com/nuhippies/image/upload/v1645404934/Nu%20Hippies/icons/tshirt_3_w34l7k.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    cursor: $pointer-cursor;
    transition: 0.8s;
    &:hover {
      transform: scale(1.07);
    }
  }
  a {
    z-index:5;
  }
}
}

.hover-products-menu{
  position: absolute;
  top: 0px;
  left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $main-background-color;
  color: $text-color;
  width: 100vw;
  height: 100vh;
  z-index: 12;
  padding-top: 50px;
  a {
    color: $text-color;
  }
}

.logo{
  background-image: url("https://res.cloudinary.com/nuhippies/image/upload/v1655114968/Nu%20Hippies/icons/mainlogo1_ypgmou.png");
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  transition: 0.8s;
  position: fixed;
  top: 0px;
  left: 50%;
  margin-left: -50px;
  z-index: 7;
}

.logo:hover{
  transform: scale(1.04);
}

.colored{
  color: $third-color;
}

.basket-icon-wrapper{
  background-image: url('https://res.cloudinary.com/nuhippies/image/upload/v1667794913/Nu%20Hippies/icons/shopping-basket_1_l2ja3u.png');
  background-size: cover;
  display: none;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: $pointer-cursor;
  transition: 0.8s;
  width: 40px;
  height: 40px;
  z-index: 5;
  .basket-number{
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: $third-color;
    position: absolute;
    background: white;
    padding: 3px 5px;
    border-radius: 50%;
    margin-top: 8px;
  }
  .basket-number-scroll{
    font-size: 11px;
  }
}
.basket-icon-wrapper:hover{
  transform: scale(1.1);
}

.logo-scroll{
  width: 60px;
  height: 60px;
  top: -4px;
  margin-left: -30px;
}

.logo-scroll-sh{
  width: 60px;
  height: 60px;
  top: -4px;
  margin-left: 20px!important;
}

.basket-icon-scroll{
  width: 30px;
  height: 30px;
  top: 20px;
}

.products-navbar-icon-scroll{
  width: 40px!important;
  height: 40px!important;
  top: 10px!important;
}

.donation-icon{
  background-image: url("https://res.cloudinary.com/nuhippies/image/upload/v1653243161/Nu%20Hippies/icons/face_eariem.png");
  background-size: cover;
  position: fixed;
  width: 58px;
  height: 58px;
  bottom: 20px;
  left: 40px;
  cursor: $pointer-cursor;
  display: none;
  flex-direction: column;
  align-items: center;
  z-index: 5;
}

.donation-text{
  position: absolute;
  top: -37px;
  font-size: 12px;
  width: 100px;
  text-align: center;
  color: $text-color;
  border: 1px solid $text-color;
  border-radius: 5px;
  padding: 5px 12px;
}

@keyframes menu-in{
  0% {left: -100vw}
  100% {left: 0px}
}

@keyframes menu-out {
  0% {left: 0px}
  100% {left: -100vw}
}