@import "./variables.scss";

// max 800px screens
@media only screen and (max-width: 800px) {
  //Chats
.chat-page{
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .chats-container-left{
    height: auto;
    margin-right: 0px;
  }
  .chats-form-right{
    position: absolute;
    bottom: 10px;
    padding-top: 20px;
  }
  .chat-preview-wrapper{
  }
  .username-picture-onechat{
    flex-direction: row;
    background: $main-background-color;
    width: 100vw;
    padding: 22px 0 12px 0;
    z-index: 2;
    border-bottom: 1px solid $text-color;
    position: fixed;
    top: 0px;
    a{
      text-decoration: none;
      color: $text-color; 
    }
    .chat-back{
      position: absolute;
      left: 30px;
      font-size: 20px;    
      &:hover{
        color: $third-color;
      } 
    }
    h3{
      font-size: 16px;
    }
    img{
      width: 35px;
      height:35px;
      margin-right: 10px;
    }
  }
  .conversation-input-wrapper{
    .chat-form{
      position: fixed;
      bottom: 0px;
      width: 100vw;
      background: $main-background-color;
      padding: 5px 0 5px 0;
    }
    textarea{
    }
  }
  .conversation-wrapper{
    padding-top: 190px;
  }
  .conversation-wrapper-reversed{
    height: 100vh;
    margin-bottom: 30px;
  }
  .chat-user-texts{
  }

  .no-messages-header{
    font-size: 25px;
  }
}
//Products
  .products-page{
    overflow-x: hidden;
    .products-loading{
      width: 330px;
      height: 186px;
      margin-top: - 93px;
      margin-left: -165px;
    }
    .search-icon{
      width: 50px;
      height: 50px;
    }
    .search-wrapper{
      form{
        height: 50px;
      }
      .search-results-container{
        margin-top: -14px;
      }
    }
    .big-name-products{
      font-size: 50px;
    }
  .products-banner{
    height: 200px;
    .products-side-slogan{
      display: none;
    }
    img {
      width: 140px;
      height: 70px;
      left: -70px;
    }
  }
}
  .footer-wrapper{
    flex-direction: column;
    .footer-menu-wrapper{
      margin-top: 100px;
      order: 1;
      text-align: center;
    }
  }
  .social-media-icons{
    padding-bottom: 90px;
    margin: 30px 0 -20px 0!important;
    order: 2;
  }
  //navbar and second hand navbar
  .second-hand-navbar{
    .slogan-header{
      font-size: 15px;
    }
  }
  .navbar{
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    .products-navbar{
      left: 5px;
      .products-navbar-icon{
        width: 45px;
        height: 45px;
        top: 20px;
        left: 20px;
      }
      .products-navbar-icon-scroll{
        width: 35px!important;
        height: 35px!important;
        top: 5px!important;
      }
      .hover-products-menu{
        padding-top: 0px;
        top: 0px;
        height: 100vh;
        nav {
          transform: scale(0.35);
        }
        .products-menu-close{
          right: 20px;
          top: 35px;
          img {
          width: 30px;
          height: 30px;
          }
        }
      }
    }
    .products-navbar-item{
      font-size: 14px;
    }
    h3{
      font-size: 17px;
    }
  }
  .product-wrapper{
    transform: scale(0.8);
    margin: 10px 10px 0px 10px!important;
  }

  //Single Product 
  .single-product-section{
    margin-top: 15px;
    .basket-added-wrapper{
      height: 50vh;
      width: 100vw;
      right: 0%;
      margin-right: 0px;
      border-radius: 0px;
      h1{
        font-size: 20px;
      }
      .basket-added-btn{
        font-size: 15px;
        padding: 6px 15px;
        font-weight: 700;
        margin: 20px 15px;
      }
    }
  }
  .single-product-section .product-color-wrapper, .single-product-section .product-size-wrapper {
    padding: 6px 12px;
    font-size: 15px;
  }
  .product-price{
    font-size: 27px!important;
  }
  .single-product-section h1{
    font-size: 22px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .product-discount{
    font-size: 17px!important;
  }
  .quantity-add-wrapper{
    flex-direction: column;
  }
  .quantity-bar{
    margin-bottom: 20px;
  }
  .single-product-image{
    width: 200px!important;
    height: 250px!important;
  }
  .product-size, .quantity-bar{
    font-size: 20px!important;
  }
  .single-product-images-wrapper{
    margin: 0 auto!important;
  }
  .single-product-side-info-wrapper{
    align-items: center!important;
    margin: 0 auto!important;
  }
  .product-description{
    text-align: center;
    width: 85vw!important;
  }
  .authorization-section{
    padding-top: 20px!important;
  }
  .authorization-wrapper{
    font-size: 19px!important;
    transform: rotate(-5deg) scale(0.85)!important;
    font-weight: 400;
    .classic-btn{
      width: 160px;
      height: 40px;
      line-height: 40px;
    }
  }
  .register{
    h1{
    font-size: 20px;
  }
}
  .auth-btn-wrapper{
    position: static!important;
    align-self: center!important;
    margin-top: 30px;
  }
  .login-bttn-wrapper{
    flex-direction: column;
    .forgot{
      margin: 20px 0 30px 0;
    }
  }
  .profile-page{
    flex-direction: column;
    align-items: center;
    .profile-icon{
      font-size: 15px;
      margin: 0 0 30px 0;
      .profile-icon-text{
        margin-bottom: 10px;
      }
      img{
        width: 60px;
        height: 60px;
      }
    }
  }
  .logout-wrapper{
    position: absolute;
    font-size: 32px;
    width: 50px;
    height: 50px;
    right: 10px;
    bottom: 10px;
  }
  .forms-wrapper{
    align-items: center;
  }
  .edit-account-page, .edit-adress-page{

}

//Baket Page 
.basket-page{
  flex-direction: column;  
  align-items: center; 
  margin-bottom: 50px;
.basket-container{
  h1{
    font-size: 12px;
  }
}
.basket-subtotal-remove{
  align-items: center;
  flex-direction: column;
  font-size: 14px;
}
.basket-remove{
  align-self: flex-start;
}
.basket-remove{
  margin-bottom: 13px;
  font-size: 12px;
}
.basket-subtotal{
  margin-top: 13px;
  font-size: 15px;
    }
    select{
      font-size: 12px;
    }
.basket-image-title{
  flex-direction: column;
}
.color-size-price{
  flex-direction: column;
  font-size: 12px;
  width: 40px;
  .basket-price{
    font-size: 14px;
  }
}
.checkout-button-total-price{
  width: 70vw;
  font-size: 17px;
}
.checkout-button{
  font-size: 17px;
  width: 50vw;
}
.basket-item{
  // display: flex;
  justify-content: space-around;
}
.basket-image-title{
  text-align: center;
}
  }
  .shipping-page{
    flex-direction: column;
    h2{
      font-size: 19px;
    }
  }
  .shipping-option{
    font-size: 15px;
  }
  .shipping-description{
    font-size: 13px;
  }
  .shipping-radio{
    width: 100%;
  }
  .payment-page{
    input{
      // width: 100%;
    }
    h2{
      font-size: 19px;
    }
    .payment-option{
      width: 70vw;
    }
    .payment-radio{
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .basket-preview{
    transform: scale(0.8);
    width: 90vw;
  }
  .payment-radio{
    align-items: center;
  }
  .contact-page{
    textarea{
      width: 20em;
      height: 9em;
    }
  }

  //Popup discount
  .discount-page{
    h1 {
      font-size: 28px;
      line-height: 28px;
      padding: 7px 20px;
    }
  }
  .score-window{
    align-items: flex-end;
    .popup-logo{
      top: 60px;
      margin-left: -55px;
      width: 110px;
      height: 110px;
    }
    .header-btn-score{
      padding: 12px;
      margin-bottom: 30px;
    }
    .classic-btn{
      margin-top: 15px;
      font-size: 18px;
      width: 140px;
    }
    h2{
      font-size: 15px;
      padding: 0px;
      width: 100%;
    }
    img{
      display: none;
    }
  }
  .discount-popup-img{
    width: 80px;
    height: 80px;
  }
  .discount-plus{
    font-size: 28px;
    margin-left: -35px;
    margin-top: 0px;
  }
  .discount-stars{
    width: 60px;
    height: 60px;
    left: 17px;
    top: 25px;
  }
.checkout-form{
  width: 70vw;
  padding-right: 0px;
}
.image-checkout-wrapper{
  .image-checkout-name{
    width: 100%;
  }
}
.confirmation-page{
  .checkout-buttons{
    transform: scale(0.8);
    .left, .right{
      padding: 7px 15px;
    }
  }
  h2{
    font-size: 20px;
    width: 80vw;
  }
  h3{
    font-size: 15px;
    margin-bottom: 10px;
  }
  .total-price-checkout-wrapper{
    flex-direction: column;
    padding: 0px;
    max-width: 90vw;
    div {
      margin-top: 0px;
    }
    .order-confirmation-details{
      font-size: 14px;
      div {
        margin-top: 10px;
      }
    }

  }
  .order-items-container{
    border-bottom: 1px solid black;
  }
}
.products-page.categorized{
    margin-top: -30px;
    .product-banner{
      height: 140px;
    }
}
.done-page{
  h1{
    font-size: 18px;
  }
}
.about-page{
  .big-name-about{
    font-size: 50px;
  }
  .about-side-slogan{
    transform: scale(0.35) rotate(-10deg);
    transform-origin: left;
    left: 5px;
  }
  .form-wrapper{
    width: 87vw;
  }
}
.order-confirmation-details{
  margin-left: 0px;
}
.terms-page{
  transform: scale(0.7);
  transform-origin: top;
}
.privacy-page{
  transform: scale(0.7);
  transform-origin: top;
}
.products-category-wrapper{
  transform: scale(0.5);
}

//Flowers
.flower-content{
  display: none;
}
}

// max 600px screens
@media only screen and (max-width: 600px) {
  //Popup Discount
  .discount-page{
    .discount-header{
      h1{
        font-size: 30px;
        line-height: 40px;
      }
      .cancel-game{
        transform: scale(0.8);
      }
      .password-change-notification{
        width: 90%;
        margin-left: -45%;
        font-size: 20px;
      }
    }
  }
  //Navbar and Second Hand Navbar
.second-hand-navbar{
  height: 70px;
  .slogan-header{
    display: none;
  }
  .logo{
    width: 80px;
    height: 80px;
    margin-left: -10px;
  }
  .logo-scroll-sh{
    width: 50px;
    height: 50px;
    margin-left: 5px!important;
  }
}

.menu{
    left: 1px;
    .spacer{
      width: 350px;
      margin-left: -143px;
    }
  .one, .two, .three, .four {
    width: 60px;
    height: 60px;
  }
}
  .menu:hover .item {
    opacity: 1;
    top: 80px;
  }
//Navbar
  .navbar{
    height: 70px;
    .menu{
      margin-left: -30px;
    }
    .products-navbar{
      .hover-products-menu{
        flex-direction: column;
        nav {
          &:nth-of-type(1){
            margin-top: 90px!important;
          }
          &:nth-of-type(2){
            margin-bottom: 90px!important;
          }
        }
      }
      .products-navbar-icon{
        left: 10px;
        top: 10px;
      }
    }
  }
  .logo{
    width: 80px;
    height: 80px;
    margin-left: -40px;
  }
  .logo-scroll{
    width: 50px;
    height: 50px;
    top: 0px;
    margin-left: -25px;
  }

  //Basket icon
  .basket-icon-wrapper{
    position: fixed;
    left: unset;
    top: unset;
    bottom: 8px;
    right: 10px;
    width: 38px;
    height: 38px;
    .basket-number{
      font-size: 13px;
      line-height: 13px;
    }
  }

//second-hand-page
.second-hand-page{
  h1{
    font-size: 40px;
    width: 90vw;
    margin: 10px 7px;
  }
}
.icon-container{
  margin-top: 30px;
  .sh-icons{
    width: 55px;
    height: 55px;
    background-size: 40px 40px;
    margin: 0 15px;
  }
  .icon-description{
    font-size: 14px;
  }
}
//categorized-items
.categorized{
  margin-top: 70px;
  input{
    width: 190px;
    font-size: 14px;
    padding: 5px 12px;
  }
  .categories-container{
    top: 90px;
    .menu-category{
      .menu-category-header{
        font-size: 19px;
        padding: 0 30px;
      }
      .menu-item{
        font-size: 15px;
      }
    }
  }
  .product-container{
    margin-top: 0px;
  }
}
.cat-nav{
  a {
    font-size: 16px;
  }
}
.place-wrapper{
  font-size: 16px;
  max-width: 85vw;
  margin-bottom: 10px;
}

//Post Item Page
.post-item-page{
  h1{
    // top: 80px;
  }
  textarea{
    width: 87vw;
  }
  .uploaded-item-description{
    margin-bottom: 20px;
}
}

//Single Item
.single-item-page{
  .start-chat-wrapper{
    margin-top: 10px;
    font-size: 17px;
    i{
      font-size: 20px;
    }
  }
  .product-description{
    margin-top: 27px;
  }
  .category-size-wrapper{
    margin-top: 20px;
  }
  .date-single-item{
    font-size: 14px;
  }
  .place-wrapper{
    margin: 30px 0 30px 0;
  }
}

//Geocoder
.react-geocoder-results{
  max-width: 90vw;
  padding: 0 10px;
}
.react-geocoder-item{
  font-size: 15px;
  text-align: center;
}

//Orders Page
.orders-page{
  img{
    width: 100px;
    height: 100px;
  }
  h1{
    font-size: 28px;
  }
}

//Ads page
.ads-page{
  img{
    width: 90px;
    height: 90px;
  }
  h1{
    font-size: 28px;
  }
}

//Edit Account
.edit-account-page{
  .password-change-notification{
    width: 90%;
    margin-left: -45%;
    font-size: 20px;
  }
}


// Terms and privacy pages
  .terms-page{
    transform: scale(0.45);
    transform-origin: top;
  }
  .privacy-page{
    transform: scale(0.4);
    transform-origin: top;
  }
  .donation-page{
    flex-direction: column;
    h1{
      font-size: 33px;
    }
    .donation{
      order: 1;
      width: 90vw;
    }
    p{
      font-size: 18px;
    }
    .donation-img{
      order: 2;
      margin-bottom: 30px;
      height: 400px;
    }
  }
  .donation-icon{
    left: 8px;
    width: 40px;
    height: 40px;
  }
  .products-page{
    .products-banner{
      img {
        display: none;
      }
    }
  }
  .discount-page{
    .popup-logo{
      top: 100px;
    }
    .header-btn-score{
      margin-bottom: 80px;
      width: 80%;
    }
  }

  // Forum
  .main-forum-page{
    h1{
      font-size: 24px;
      max-width: 90vw;
    }
    img{
      width: 200px;
      height: 200px;
    }
  }
}

//check if its phone
@media (pointer:none), (pointer:coarse) {
  //Chats
.chat-page{
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .chats-container-left{
    height: auto;
    margin-right: 0px;
  }
  .chats-form-right{
    position: absolute;
    bottom: 10px;
    padding-top: 20px;
  }
  .chat-preview-wrapper{
  }
  .username-picture-onechat{
    flex-direction: row;
    background: $main-background-color;
    width: 100vw;
    padding: 22px 0 12px 0;
    z-index: 2;
    border-bottom: 1px solid $text-color;
    position: fixed;
    top: 0px;
    a{
      text-decoration: none;
      color: $text-color; 
    }
    .chat-back{
      position: absolute;
      left: 30px;
      font-size: 20px;    
      &:hover{
        color: $third-color;
      } 
    }
    h3{
      font-size: 16px;
    }
    img{
      width: 35px;
      height:35px;
      margin-right: 10px;
    }
  }
  .conversation-input-wrapper{
    .chat-form{
      position: fixed;
      bottom: 0px;
      width: 100vw;
      background: $main-background-color;
      padding: 5px 0 5px 0;
    }
    textarea{
    }
  }
  .conversation-wrapper{
    padding-top: 190px;
  }
  .conversation-wrapper-reversed{
    height: 100vh;
    margin-bottom: 30px;
  }
  .chat-user-texts{
  }

  .no-messages-header{
    font-size: 25px;
  }
}
//navbar and second hand navbar
  .second-hand-navbar{
    .slogan-header{
      display: none;
    }
  }

  //Basket icon
  .basket-icon-wrapper{
    position: fixed;
    left: unset;
    top: unset;
    bottom: 8px;
    right: 10px;
    width: 38px;
    height: 38px;
    .basket-number{
      font-size: 13px;
      line-height: 13px;
    }
  }
}