@import "./dots-menu.scss";
@import "./variables.scss";
@import "./main-menu.scss";
@import "./phone-menu-wrapper.scss";
@import "./flower.scss";
@import "./title-revealing.scss";
@import "./navbar.scss";
@import "./home.scss";
@import "./buttons.scss";
@import "./second-hand-navbar.scss";
@import "./second-hand.scss";
@import "./maps.scss";
@import "./chat.scss";
@import "./forum.scss";

html, body{
  height: 100%;
  font-family: 'Jura', sans-serif;
  font-weight: 700;
  background: $main-background-color;
  // cursor: url('https://res.cloudinary.com/nuhippies/image/upload/v1651164355/Nu%20Hippies/icons/victory_1_vhbvys.png') 25 25, auto;
}

  a{
    cursor: $pointer-cursor;
  }

  p, h1, h2, h3{
    cursor: text;
  }

body {
  // overflow-x: hidden;
  color: $text-color;
  font-weight: 200;
  // background: radial-gradient(ellipse at bottom, #1b2735, #090a0f);
  // background-image: radial-gradient(circle at center, #1b2735, #090a0f);
}

.whole-page{
  min-height: 100vh;
}

// .canvas{
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   background-image: url('https://res.cloudinary.com/nuhippies/image/upload/v1650975032/Nu%20Hippies/Backgrounds/noise-512x512_xip6ex.jpg');
//   background-size: 512px 512px;
//   opacity: 0.042;
//   pointer-events: none;
//   z-index: -1;
// }

.cursor-gif{
  display: none;
  position: absolute;
}

input, textarea, select{
  padding: 5px 6px;
  margin-top: 4px;
  font-size: 15px;
  border-radius: 5px;
  border: 2px solid $text-color;
}
input:focus, textarea:focus{
  outline: $second-color;
  border: 2px solid $second-color;
}

button{
  z-index: 3;
  border: none;
}

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    -webkit-overflow-scrolling: scroll!important;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.classic-btn{
  background: $text-color;
  color: $second-color;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;
  height: 37px;
  padding: 0 22px;
  min-width: 80px;
  line-height: 37px;
  text-align: center;
  // border: 2px solid $second-color;
  cursor: $pointer-cursor;
  .loading-image{
    width: 37px;
    height: 37px;
  }
}
.loading-image-checkout{
  width: 30px;
  height: 30px;
}
.btn-loading{
  align-self: center;
}
.classic-btn:hover{
  transform: scale(1.04);
  color: $third-color;
}

* { margin: 0; padding: 0; }

//Basket page
.basket-page{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .empty-basket{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;
    h1{
      margin-top: 30vh;
      font-size: 30px;
    }
  }
  .basket-container{
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  .basket-item-wrapper{
    display: flex;
    flex-direction: column;
    margin: 30px;
    border-top: 1px solid $dark-color;
    border-bottom: 1px solid $dark-color;
    padding: 20px 0;
  }
  .basket-subtotal-remove{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
  .basket-remove{
    cursor: $pointer-cursor;
    color: $dark-color;
  }
  .basket-subtotal{
    font-size: 21px;
    font-weight: 300;
  }
  .basket-item{
    display: flex;
    justify-content: space-between;
    .basket-price{
      font-weight: 300;
      font-size: 18px;
    }
    img{
      width: 80px;
      height: 80px;
      margin-right: 30px;
    }
  }
  select{
    margin-left: 7px;
  }
  .checkout-button-total-price{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    border: 1px solid $dark-color;
    width: 20vw;
    height: 150px;
    margin-top: 30px;
    font-size: 22px;
    padding: 9px 9px 25px 9px;
  }
  .checkout-button-total-price div{
    margin-top: 10px;
  }
  .checkout-button{
    background: $dark-color;
    font-size: 20px;
    font-weight: 300;
    color: $second-color; 
    border-radius: 20px;
    text-align: center;
    width: 15vw;
    padding: 10px 19px;
    cursor: $pointer-cursor;
  }
  .checkout-button:hover{
    transform: scale(1.03);
    color: $third-color;    
  }
  .total-price{
    text-align: center;
  }
  h1{
    font-size: 23px;
    font-weight: 300;
  }
  .basket-image-title{
    display: flex;
    max-width: 60%;
  }
  .color-size-price{
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .number{
    width: 19px;
    text-align: center;
    resize: none;
    overflow: hidden;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
}

//Shipping page 
.shipping-page{
  display: flex;
  padding: 5%;
  justify-content: space-around;
  align-items: center;
  h2{
    font-weight: 300;
  }
  .basket-preview{
    margin-bottom: -60px;
  }
}
.shipping-radio{
  width: 40%;
}
.shipping-option{
  border: 1px solid $dark-color;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.shipping-option-name-radio{
  display: flex;
  align-items: flex-start;
  input{
    margin-right: 10px;
    margin-top: 5px;
    cursor: $pointer-cursor;
  }
}
.shipping-description{
  color: gray;
  font-size: 15px;
  margin-top: 5px;
}

.basket-preview{
  width: 30vw;
}

//Payment page
.payment-page{
  display: flex;
  padding: 1% 5% 5% 5%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  h2{
    font-weight: 300;
  }
  .payment-radio{
    display: flex;
    flex-direction: column;
    width: 30%;
  }
  input{
    margin: 0 8px 10px 0;
  }
  .payment-option{
    margin: 15px 0;
  }
  .paypal-icon{
    width: 80px;
    height: 80px;
    margin-bottom: -30px;
  }
}

//Checkout page
.checkout-page{
  display: flex;
  padding: 1% 5% 5% 5%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  h2{
    font-weight: 300;
  }
  .error-message {
    margin-bottom: 10px;
  }
}
.total-price-checkout-wrapper{
  border: 1px solid $dark-color;
  padding: 16px 0;
  font-size: 19px;
  font-weight: 300;
  text-align: center;
  .total-text{
    margin-bottom: 10px;
  }
  :nth-child(3){
    margin-bottom: 0px;
  }
}
.order-items-container{
  max-height: 230px;
  overflow-y: scroll;
}
.order-confirmation-details{
  margin-left: 40px;
}
.total-price-checkout-wrapper div{
  margin-top: 9px;
}
.size{
  padding-right: 20px;
}
.color{
  padding-right: 20px;
}
h2{
  padding-bottom: 10px;
}
  .shipping-preview-wrapper{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .checkout-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    a{
      text-decoration: none;
      color: $text-color;
    }
    .right-button, .left-button{
      padding: 9px 38px;
      cursor: $pointer-cursor;
      border-radius: 25px;
      font-weight: 700;
      font-size: 18px;
    }
    .right-button{
      color: $second-color;
      background: $dark-color;
      margin-left: 20px;
      line-height: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right-button:hover{
      transform: scale(1.02);
      color: $third-color;
    }
    .left-button{
      background: white;
      border: 1px solid $text-color;
      margin-right: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: flex;
    }
  }
  .forms-wrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .checkout-form{
    display: flex;
    flex-direction: column;
    width: 40vw;
    padding-right: 20px;
    input{
      margin-bottom: 9px;
    }
    select{
      margin: 3px 0 10px 0;
      padding: 5px;
    }
  }
  
  .billing-radio{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    margin-bottom: 17px;
    input{
      margin: 0 10px 10px 0;
    }
  }
  .image-checkout-wrapper{
    margin-bottom: 15px;
    display: flex;
    .image-checkout-name{
      width: 60%;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .image-checkout-description{
      color: gray;
      font-size: 13px;
    }
    .image-checkout{
      width: 60px;
      height: 60px;
      border: 1px solid gray;
      border-radius: 5%;
      background-size: cover;
      margin-right: 7px;
    }
  }

  //Order Confirmation Page
  .confirmation-page{
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
    h2{
      margin-bottom: 30px;
      text-align: center;
    }
    h3{
      text-align: center;
    }
    .classic-btn{
      margin-top: 30px;
    }
    .total-price-checkout-wrapper{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }
  }

//Authorization
.auth-page{

.auth-side{
  display: flex;
  flex-direction: column;
}
.auth-btn-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  position: absolute;
  bottom: 40px;
  right: 140px;
}
button{
  font-family: $main-font;
  font-weight: 300;
}
.auth-btn:hover{
  transform: scale(1.04);
  transition: 0.2s;
  color: $dark-color;
}
.authorization-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  h1 {
    font-size: 32px;
    font-weight: 500;
  }
}

.authorization-wrapper{
  font-size: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid $text-color;
  border-color: $text-color;
  border-width: 3px 3px 5px 5px;
  border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
  transform: rotate(-5deg);
  padding: 15px;
  cursor: $pointer-cursor;
  transition: 0.5s;
  text-align: center;
  &:hover{
    transform: scale(1.04);
  }
}

.auth-side, .authorization-wrapper, .register{
  .classic-btn{
    margin-top: 10px;
  }
}

//Register
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    padding: 20px 30px 0px 30px;
    text-align: center;
  }
  form{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  button{
    align-self: center;
  }
  input {
    width: 300px;
    height: 30px;
    margin-bottom: 20px;
  }
  .register-input-top-wrapper,
  .register-input-bottom-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
  }
}
.error-message {
  top: 60px;
}
}

.input-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}
.error-input {
  border: 2px red solid;
}

.error-message {
  color: red;
  // position: absolute;
  // top: 40px;
}

.login-bttn-wrapper{
  display: flex;
  align-items: center;
  .forgot{
    margin-top: 10px;
    margin-left: 60px;
    color: $dark-color;
    cursor: $pointer-cursor;
  }
  a{
    text-decoration: none;
  }
  .forgot:hover{
    transform: scale(1.03);
  }
}

//Forgot page 
.forgot-page, .reset-page{
  display: flex;
  justify-content: center;
  align-items: center;

  form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .classic-btn{
    margin-top: 10px;
  }
  h1{
    margin: 50px 0 100px 0;
  }
  button{
    font-family: $main-font;
  }
  input{
    margin: 6px 0;
  }
}

//Donation Page 
.donation-page{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}
.donation-img{
  height: 450px;
  border-radius: 50px;
}
.donation{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  text-align: center;
  justify-content: space-between;
  width: 60vw;
  a{
    font-weight: 400;
    color: $text-color;
  }
  h1{
    margin-bottom: 24px;
    font-size: 40px;
  }
  p{
    font-size: 20px;
    line-height: 22px;
  }
  .classic-btn{
    margin-top: 30px;
  }
}

//Done page
.done-page{
  display: flex;
  justify-content: center;
  align-items: center;
  h1{
    text-align: center;
    padding: 0 20px;
  }
  .progress-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  progress {
    display:inline-block;
    width: 70vw;
    height: 10px;
    margin-top: 50px;
    border-radius: 0px;
    text-align: left;
    position:relative;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
  }
  progress::-webkit-progress-bar {
    height: 10px;
    width: 70vw;
    border-radius: 30px;
  }
  progress::-webkit-progress-value {
    display:inline-block;
    float: left;
    height: 10px;
    width: 70vw;
    margin:0px -10px 0 0;
    background: $third-color;
    border-radius: 30px;
  }
  // progress:after {
  //   margin:-26px 0 0 -7px;
  //   padding:0;
  //   display:inline-block;
  //   float:left;
  // }
}

//Products
.products-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
  .search-icon{
    cursor: $pointer-cursor;
    &:hover{
      transform: scale(1.05);
      transition: 0.4s linear;
    }
  }
  .search-wrapper{
    position: relative;
    z-index: 5s;
    form{
      height: 64px;
    }
    input{
      width: 183px;
      position: relative;
      left: 50%;
      margin-left: -91.5px;
      height: 35px;
      border-radius: 50px;
      z-index: 2;
    }
    .search-results-container{
      position: absolute;
      background: white;
      border-radius: 5px;
      width: 250px;
      margin-left: -80px;
      z-index: 20;
      margin-top: -28px;
      .search-result-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        width: 210px;
        height: 210px;
        border-bottom: 1px solid $gray-color;
        &:hover{
          img{
            width: 154px;
            height: 154px;
          }
        }
        .search-result-name{
          text-align: center;
          position: relative;
          top: -85px;
        }
      img {
        width: 150px;
        height: 150px;
        position: absolute;
        margin-bottom: -20px;
      }
    }
    }
  }
  .products-loading{
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -140px;
  }
  .products-side-slogan{
    border: solid $text-color;
    border-color: $text-color;
    position: absolute;
    left: 14px;
    top: 0px;
    border-width: 3px 3px 5px 5px;
    border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
    transform: rotate(-10deg);
    padding: 20px;
    width: 250px;
    text-align: center;
  }
  h2{
    font-family: $logo-font;
    padding-bottom: 0px;
  }
  .products-flower-wrapper{
    display: flex;
    justify-content: center;
  }
  .big-name-products{
    font-size: 150px;
    font-weight: bold;
    text-align: center;
    max-width: 90vw;
  }
  .products-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 100vw;
    height: 375px;
    z-index: 1;
    // background-image: url('https://www.vitafoodsinsights.com/sites/vitafoodsinsights.com/files/07_18INS_WhySupplement_1540x800_v2-1_6.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;
    position: relative;
    img {
      width: 200px;
      height: 200px;
      position: absolute;
      top: 0px;
      left: -100px;
      margin-top: 50px;
      transition: 1s linear;
    }
  }
  .products-category-wrapper{
    display: flex;
    .products-category-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 40px;
      z-index: 3;
      cursor: $pointer-cursor;
      border: solid $text-color;
      border-color: $text-color;
      border-width: 3px 3px 5px 5px;
      border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
      transform: rotate(-5deg);
      padding: 20px;
      width: 250px;
      &:hover{
        transform: scale(1.05);
        transition: 0.4s linear;
      }
      .products-category-item-background-one{
        background: url("https://res.cloudinary.com/nuhippies/image/upload/v1650097508/Nu%20Hippies/products/Clothes/Pure%20Hemp%20and%20Cotton%20Bags/hempb-14_01_mebj9b__1_-removebg-preview_y12ofc.png");
        background-size: contain;
        width: 200px;
        height: 200px;
    }
    .products-category-item-background-two{
      background: url("https://res.cloudinary.com/nuhippies/image/upload/v1650046487/Nu%20Hippies/products/GreeNourish%20Complete/GreeNourish-pot-500x500_wgax7u-removebg-preview_ryhqyj.png");
      background-size: contain;
      width: 200px;
      height: 200px;
  }
      .products-category-item-name{
        color: $text-color;
        text-align: center;
        margin-bottom: 5px;
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
    video{
      width: 100%;
      // max-width: 1200px;
      height: 100%;
      // max-height: 70vh;
      position: relative;
      object-fit: cover;
      object-position: center;
      z-index: -1;
    }
    .shadow{
      width: 100%;
      height: 50vh;
      position: absolute;
      box-shadow: inset 0px 27px 127px -40px  rgba(250,203,82,1), 0px -42px 103px -27px rgba(250,203,82,1);
      ;
    }
    .shadow-scroll{
      box-shadow: inset 0px 27px 127px -40px  #52C1C7;
    }
  a{
    text-decoration: none;
    color: inherit;
  }
  .product-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    flex-wrap: wrap;
  }
  .product-wrapper {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
  }
  .product-preview-image {
    width: 280px;
    height: 280px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.4s linear;
  }
  .product-preview-name {
    width: 280px;
    max-height: 90px;
    overflow: hidden;
    padding: 3px 8px;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: center;
  }
  .product-preview-price-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
  .product-preview-price{
    font-size: 30px;
    font-weight: 400;
  }
  .product-preview-discount{
    display: flex;
    justify-content: center;
    align-items: center;
    background: $second-color;
    padding: auto;
    width: 60px;
    height: 24px;
    font-size: 20px;
    font-weight: 300;
    color: $dark-color;
  }
}
}

.more{
  display: flex;
  flex-direction: column;
  margin: 40px auto 20px auto;
  width: 110px;
  text-align:  center;
  cursor: $pointer-cursor;
  font-weight: 400;
}

//categorized products
.products-page.categorized{
  flex-direction: column;
  align-items: center;
}
.cat-nav{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 30px 0 10px 0;
  width: 100vw;
  a{
    text-decoration: none;
    color: $text-color;
    padding-right: 10px;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .sign{
    margin-right: 10px;
  }
  a:hover{
    color: $third-color;
  }
  .cat-nav-item:last-child{
      color: $third-color;
  }
  .symbol{
    margin: 0 12px 0 5px;
  }
}

//Single Product
.single-product-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
.single-product-wrapper{
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
h1{
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  width: autos;
}
.single-product-images-wrapper{
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.single-product-image{
  width: 310px;
  height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.side-images-container{
  max-height: 400px;
  margin-right: 15px;
}
.side-image{
  width: 50px;
  height: 52px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: $pointer-cursor;
  border: 1px solid $dark-color;  
  margin-bottom: 5px;
  filter: brightness(0.6);
}
.single-product-side-info-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  h2{
    margin-top: 20px;
    color: $fourth-color;
    font-weight: 500;
    font-size: 25px;
  }
}

.single-product-name{
  margin-bottom: 20px;
}
.product-price-wrapper{
  display: flex;
  justify-content: left;
  align-items: center;
.product-price{
  font-size: 29px;
  font-weight: 400;
  margin: 0 auto;
}
.product-discount{
  display: flex;
  justify-content: center;
  align-items: center;
  background: $second-color;
  padding: auto;
  width: 60px;
  height: 24px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 300;
}
}
.product-size-container{
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  margin-top: 30px;
}
.product-size-wrapper{
  border: 1px solid $text-color;
  padding: 5px 20px;
  cursor: $pointer-cursor;
  margin: 0 8px;
}
.product-size{
  margin: 33px 0 16px 0;
  font-size: 26px;
}
.chosen-size{
  background: $third-color; 
}
.chosen-side-image{
  border: 1px solid $third-color;
  filter: brightness(1);
}
.product-colors-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.product-color-wrapper{
  margin: 8px;
  padding: 10px 20px;
  cursor: $pointer-cursor;
}
.chosen-color{
  background: $third-color;
  color: white;
  border-radius: 25px;
}
.number{
  width: 47px;
  text-align: center;
  resize: none;
  overflow: hidden;
}
.sign{
  cursor: $pointer-cursor;
}
.quantity-add-wrapper{
  display: flex;
  align-items: center;
  margin-top: 20px;
  a{
    text-decoration: none;
  }
}
.quantity-bar{
  font-size: 26px;
  margin-right: 30px;
  select{
    font-size: 15px;
    padding: 5px;
    cursor: $pointer-cursor;
  }
  select:focus{
    outline: none;
  }
  label{
    margin-right: 7px;
  }
}
.product-info-container{
  display: flex;
  margin-top: 40px;
  .active{
    background: $third-color;
    color: white;
  }
  .product-info-item{
    padding: 5px 15px;
    margin: 0 10px;
    cursor: $pointer-cursor;
    border-radius: 25px;
  }
}
.product-description{
  width: 70vw;
  margin-top: 40px;
  line-height: 22px;
  p{
    margin: 20px 0 50px 0;
    font-weight: 300;
  }
  h1{
    font-weight: 300;
    margin-bottom: 10px;
  }
}
.img-description{
  max-width: 70vw;
}
.img-ingredients{
  max-width: 70vw;
  display: flex;
  align-self: center;
}
.added-wrapper-hide{
  animation: .5s added-hide linear;
}
.basket-added-wrapper{
  width: 60vw;
  height: 70vh;
  border: 1px solid $text-color;
  border-bottom: none;
  border-radius: 50px 50px 0 0;
  position: fixed;
  bottom: 0%;
  right: 50%;
  margin-right: -30vw;
  background: $main-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  animation: .5s basket-added linear;
  color: $text-color;
  h1{
    color: $text-color;
    font-weight: 600;
  }
  .basket-added-buttons{
    display: flex;
  }
  .basket-added-btn{
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    background: $text-color;
    border-radius: 25px;
    color: $second-color;
    cursor: $pointer-cursor;
    margin: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .basket-added-btn:hover{
    color: $third-color;
    transform: scale(1.04);
  }
  a {
    text-decoration: none;
    color: $second-color;
  }
}
}

@keyframes basket-added {
  0% { bottom: -100% ;}
  100% { bottom: 0%; }
}

@keyframes added-hide {
  0% { bottom: 0% ;}
  100% { bottom: -100%; }
}

//Profile
.profile-page{
  display: flex;
  justify-content: center;
  a{
    text-decoration: none;
    color: $dark-color;
  }
  .profile-icons-container{
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 5%;
  }
  .profile-icon{
    display: flex;
    width: 300px;
    border: 1px solid lightgray;
    margin: 0 40px 40px 0;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 300;
    cursor: $pointer-cursor;
    transition: 0.8s;
    .profile-icon-text{
      margin-bottom: 20px;
      text-align: center;
    }
    .profile-icon-description{
      width: 100%;
    }
    .description{
      font-size: 13px;
      color: $gray-color;
      font-weight: 100;
      text-align: center;
    }
    img{
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
  }
  .profile-icon:hover{
    box-shadow: $third-color 0px 30px 60px -12px inset, $third-color 0px 18px 36px -18px inset;
  }
}
.logout-wrapper{
  cursor: $pointer-cursor;
  width: 64px;
  height: 64px;
  font-size: 45px;
  position: fixed;
  left: 30px;
  bottom: 20px;
  z-index: 3;
}
.logout-wrapper:hover{
  color: $third-color;
  transform: scale(1.05);
}
.account-nav{
  display: flex;
  justify-content: center;
  color: $text-color;
  font-size: 19px;
  .auth{
    cursor: $pointer-cursor;
    color: $text-color;
  }
  .auth:hover{
    color: $third-color;
  }
  a{
    text-decoration: none;
    color: $text-color;
    margin-right: 10px;
  }
  a:hover{
    color: $third-color;
  }
  .sign{
    margin-right: 10px;
  }
  .sign-auth{
    margin-right: 3px;
  }
  :last-child{
    color: $third-color;
  }
}

//Orders page
.orders-page{
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}
h1{
  text-align: center;
  max-width: 90vw;
}
.no-orders-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  img{
    margin-top: 12px;
    width: 150px;
    height: 150px;
  }
}

// Ads paige 
.ads-page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .edit-delete-wrapper{
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 0 auto;
    div:hover, a:hover{
      color: $third-color;
    }
  }
  .classic-btn{
    margin: 20px 30px;
  }
  .delete-item{
    cursor: pointer;
  }
  .no-orders-wrapper{
    img{
      margin-top: 25px;
      width: 120px;
      height: 120px;
    }
  }
  h1{
    max-width: 90vw;
    text-align: center;
  }
  a{
    text-decoration: none;
    color: $text-color;
  }
  .product-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .product-wrapper {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
  }
  .product-preview-image {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.4s linear;
  }
  .product-preview-name {
    width: 200px;
    max-height: 80px;
    overflow: hidden;
    padding: 3px 8px;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: center;
  }
  .product-preview-price-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
  .product-preview-price{
    font-size: 22px;
    font-weight: 1000;
  }
}
}

//About page
.about-page{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  .story-header{
    border: solid;
    border-color: $third-color;
    left: 14px;
    top: 100px;
    border-width: 3px 3px 5px 5px;
    border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
    padding: 13px;
    width: 190px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    &:nth-of-type(3){
      margin-top: 90px;
    }
  }
  .about-side-slogan{
    border: solid;
    border-color: $second-color;
    position: absolute;
    left: 14px;
    top: 100px;
    border-width: 3px 3px 5px 5px;
    border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
    transform: rotate(-10deg);
    padding: 20px;
    width: 250px;
    text-align: center;
    font-family: $logo-font;
  }
  .big-name-about{
    font-size: 150px;
    font-weight: bold;
    text-align: center;
    max-width: 90vw;
    font-family: $logo-font;
  }
  .form-wrapper{
    width: 70vw;
  }
  h1{
    text-align: center;
    margin-bottom: 20px;
  }
  p{
    font-size: 18px;
    line-height: 23px;
  }
}

// Contact page
.contact-page{
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  .form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
  }
  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  input, textarea{
    margin-bottom: 10px;
  }
  .sent-message-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px);
    h2{
      margin-top: -50px;
    }
    a{
      text-decoration: none;
    }
    .classic-btn{
      margin-top: 100px;
    }
  }
}

// Edit account page
.edit-account-page, .edit-adress-page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  .classic-btn{
    margin: 17px;
    align-self: center;
  }

  .edit-line-container{
    display: flex;
    flex-direction: column;
    margin-top: 2%;
  }
  .edit-line{
    font-size: 22px;
    margin-bottom: 12px;
  }
  .bold{
    font-weight: 400;
    margin-right: 15px;
  }
  form{
    display: flex;
    flex-direction: column;
    font-size: 19px;
    font-weight: 300;
    min-width: 30vw;
  }
  input{
    font-size: 17px;
    font-weight: 200;
    font-family: $main-font;
  }
  button{
    font-family: $main-font;
  }
  label{
    margin-bottom: 3px;
    margin-top: 15px;
  }
  .edit-account-button{
    background: $dark-color;
    color: $second-color;
    border-radius: 10px;
    font-size: 22px;
    font-weight: 300;
    height: 45px;
    width: 190px;
    z-index: 3;
    line-height: 45px;
    text-align: center;
    cursor: $pointer-cursor;
    align-self: center;
    margin-top: 15px;
  }
}
.password-change-notification{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
  height: 300px;
  border: 1px solid $text-color;
  position: absolute;
  font-weight: 500;
  font-size: 23px;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -250px;
  background: $main-background-color;
  z-index: 3;
  border-radius: 10px;
  .buttons{
    display: flex;
    margin-top: 7px;
  }
}

//edit account

.edit-account-page{
  .error-message{
    margin-top: 5px;
  }
}

//edit adress
.edit-adress-page{
  .shipping-billing-edit{
    display: flex;
    flex-direction: column;
  }
  .billing-edit-container{
    margin-left: 70px;
  }
  .isBilling{
    margin-top: 40px;
  }
  h2{
    margin: 30px 0 10px 0;
  }
}

//Discount popup
.discount-page{
  display: flex;
  justify-content: center;
  background: $main-background-color;

  .discount-header{
    position: absolute;
    margin: 0 auto;
    visibility: hidden;
    top: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .password-change-notification{
      cursor: default;
    }
    .buttons{
      button{
        margin: 12px 30px;
      }
    }
    .second-hand-navbar{
      h1{
        display: none;
      }
    }
    .cancel-game{
      cursor: pointer;
      margin: 0 auto;
      font-size: 20px;
      height: 40px;
      line-height: 40px;
      width: 170px;
      background: $text-color;
      color: $second-color;
      text-align: center;
      border-radius: 25px;
      font-weight: 700;
      &:hover{
        color: $third-color;
      }
    }
    h1{
      margin-top: -135px;
      margin-bottom: 30px;
      color: $text-color;
      font-size: 70px;
      text-align: center;
      padding: 10px 20px;
      border-radius: 8px;
    }
  }
}

.show-discount{
  position: absolute;
  top: 0px;
  width: 100vw;
  min-height: 100vh;
  z-index: 8;
  cursor: url('./images/frames/1.gif') 37 37, auto;
  h1{
    cursor: url('./images/frames/1.gif') 37 37, auto;
  }
}

.discount-popup-wrapper{
  // background-image: url("https://res.cloudinary.com/nuhippies/image/upload/v1639599163/Nu%20Hippies/icons/nazi_osdpkq.png");
  // background-size: contain;
  // width: 200px;
  // height: 200px;
  position: absolute;
  transition: 0.3s;
  top: -400px;
  left: -400px;
  visibility: hidden;
  display: block;
}

.discount-popup-img{
  background-image: url("https://res.cloudinary.com/nuhippies/image/upload/v1647314930/Nu%20Hippies/icons/putin-face-115498498412qs003gfss_eyzlgc-removebg-preview_llwmjt.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  height: 200px;
}

.discount-stars{
  width: 140px;
  height: 140px;
  position: relative;
  left: 75px;
  top: 70px;
}

.discount-plus{
  animation: discount-plus-size 1110ms linear;
  font-size: 50px;
  font-weight: 500;
  color: $second-color;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

@keyframes discount-plus-size{
  0% { transform: scale(1.4) ;}
  20% { transform: scale(2.2) ;}
  40% { transform: scale(3) ;}
  60% { transform: scale(2.2) ;}
  80% { transform: scale(1.4) ;}
  100% { transform: scale(1) ;}
}

.score-window{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  bottom: 0px;
  background: $main-background-color;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  line-height: 29px;
  .popup-logo{
    background: url('https://res.cloudinary.com/nuhippies/image/upload/v1646061516/Nu%20Hippies/Backgrounds/FullLogo_Transparent_e1l1fn.png') no-repeat;
    background-size: contain;
    width: 140px;
    height: 140px;
    position: absolute;
    top: 25px;
    left: 50%;
    margin-left: -70px;
  }
  .header-btn-score{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    border: solid $text-color;
    border-color: $text-color;
    border-width: 3px 3px 5px 5px;
    border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
    transform: rotate(-2deg);
    padding: 20px;
  }
  .score{
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url('https://res.cloudinary.com/nuhippies/image/upload/v1650975032/Nu%20Hippies/Backgrounds/noise-512x512_xip6ex.jpg');
    background-size: 512px 512px;
    opacity: 0.042;
    pointer-events: none;
  }
  img {
    height: calc(100vh - 280px);
    filter: brightness(0.9);
    border-radius: 50%;
    margin-left: 40px;
  }
  h2{
    font-weight: 400;
    width: 47vw;
  }
  .highlighted{
    color: $dark-color;
  }
.classic-btn{
  margin-top: 40px;
}
.box{
  margin:25px;
  width:250px;
  height:250px;
  background:#fff;
  border: solid black;
  border-color: black;
  float:left; 
}
.box2{
  width:350px;
  border-width: 3px 3px 5px 5px;
  border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
  transform: rotate(-2deg);
}
.oddboxinner{
  margin:15px;
  transform: rotate(-2deg);
}
.evenboxinner{
  transform: rotate(2deg);
  margin:15px;
  padding:0 5px;
  float:right;
  background:#ddd;
  border:1px solid #222;
  box-shadow:3px 3px 0 #222;
}
}

@keyframes cursor {
  0% {
    cursor: url("./images/frames/1.gif") $slap-pointer-half-size, auto;
  }
  2% {
    cursor: url("./images/frames/2.gif") $slap-pointer-half-size, auto;
  }
  4% {
    cursor: url("./images/frames/4.gif") $slap-pointer-half-size, auto;
  }
  6% {
    cursor: url("./images/frames/5.gif") $slap-pointer-half-size, auto;
  }
  8% {
    cursor: url("./images/frames/7.gif") $slap-pointer-half-size, auto;
  }
  10% {
    cursor: url("./images/frames/8.gif") $slap-pointer-half-size, auto;
  }
  12% {
    cursor: url("./images/frames/10.gif") $slap-pointer-half-size, auto;
  }
  14% {
    cursor: url("./images/frames/11.gif") $slap-pointer-half-size, auto;
  }
  16% {
    cursor: url("./images/frames/13.gif") $slap-pointer-half-size, auto;
  }
  18% {
    cursor: url("./images/frames/14.gif") $slap-pointer-half-size, auto;
  }
  20% {
    cursor: url("./images/frames/16.gif") $slap-pointer-half-size, auto;
  }
  22% {
    cursor: url("./images/frames/17.gif") $slap-pointer-half-size, auto;
  }
  24% {
    cursor: url("./images/frames/19.gif") $slap-pointer-half-size, auto;
  }
  26% {
    cursor: url("./images/frames/20.gif") $slap-pointer-half-size, auto;
  }
  28% {
    cursor: url("./images/frames/22.gif") $slap-pointer-half-size, auto;
  }
  30% { 
    cursor: url("./images/frames/23.gif") $slap-pointer-half-size, auto;
  }
  32% {
    cursor: url("./images/frames/25.gif") $slap-pointer-half-size, auto;
  }
  34% {
    cursor: url("./images/frames/26.gif") $slap-pointer-half-size, auto;
  }
  36% {
    cursor: url("./images/frames/28.gif") $slap-pointer-half-size, auto;
  }
  38% {
    cursor: url("./images/frames/29.gif") $slap-pointer-half-size, auto;
  }
  40% {
    cursor: url("./images/frames/31.gif") $slap-pointer-half-size, auto;
  }
  42% {
    cursor: url("./images/frames/32.gif") $slap-pointer-half-size, auto;
  }
  46% {
    cursor: url("./images/frames/34.gif") $slap-pointer-half-size, auto;
  }
  48% {
    cursor: url("./images/frames/35.gif") $slap-pointer-half-size, auto;
  }
  50% {
    cursor: url("./images/frames/37.gif") $slap-pointer-half-size, auto;
  }
  52% {
    cursor: url("./images/frames/38.gif") $slap-pointer-half-size, auto;
  }
  54% {
    cursor: url("./images/frames/40.gif") $slap-pointer-half-size, auto;
  }
  56% {
    cursor: url("./images/frames/41.gif") $slap-pointer-half-size, auto;
  }
  58% {
    cursor: url("./images/frames/43.gif") $slap-pointer-half-size, auto;
  }
  60% {
    cursor: url("./images/frames/44.gif") $slap-pointer-half-size, auto;
  }
  62% {
    cursor: url("./images/frames/46.gif") $slap-pointer-half-size, auto;
  }
  64% {
    cursor: url("./images/frames/47.gif") $slap-pointer-half-size, auto;
  }
  66% {
    cursor: url("./images/frames/49.gif") $slap-pointer-half-size, auto;
  }
  68% {
    cursor: url("./images/frames/50.gif") $slap-pointer-half-size, auto;
  }
  70% {
    cursor: url("./images/frames/52.gif") $slap-pointer-half-size, auto;
  }
  72% {
    cursor: url("./images/frames/53.gif") $slap-pointer-half-size, auto;
  }
  74% {
    cursor: url("./images/frames/55.gif") $slap-pointer-half-size, auto;
  }
  76% {
    cursor: url("./images/frames/56.gif") $slap-pointer-half-size, auto;
  }
  78% {
    cursor: url("./images/frames/58.gif") $slap-pointer-half-size, auto;
  }
  80% {
    cursor: url("./images/frames/59.gif") $slap-pointer-half-size, auto;
  }
  82% {
    cursor: url("./images/frames/61.gif") $slap-pointer-half-size, auto;
  }
  84% {
    cursor: url("./images/frames/62.gif") $slap-pointer-half-size, auto;
  }
  86% {
    cursor: url("./images/frames/63.gif") $slap-pointer-half-size, auto;
  }
  88% {
    cursor: url("./images/frames/64.gif") $slap-pointer-half-size, auto;
  }
  90% {
    cursor: url("./images/frames/65.gif") $slap-pointer-half-size, auto;
  }
  92% {
    cursor: url("./images/frames/66.gif") $slap-pointer-half-size, auto;
  }
  94% {
    cursor: url("./images/frames/67.gif") $slap-pointer-half-size, auto;
  }
  96% {
    cursor: url("./images/frames/67.gif") $slap-pointer-half-size, auto;
  }
  98% {
    cursor: url("./images/frames/67.gif") $slap-pointer-half-size, auto;
  }
  100% {
    cursor: url("./images/frames/67.gif") $slap-pointer-half-size, auto;
  }
}

//footer 
.footer-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  // flex-wrap: wrap;
  width: 100%;
  height: 200px;
  padding-bottom: 30px;
  z-index: 5;
  border-top: 1px solid $text-color;
  a {
    color: $text-color;
  }
  .footer-menu-wrapper{
    z-index: 2;
  }
  .footer-menu-wrapper li{
    list-style-type: none;
    line-height: 25px;
    cursor: $pointer-cursor;
  }
  .footer-menu-wrapper li:hover{
    transform: scale(1.05);
    color: $third-color;
  }
  .social-media-icons{
    z-index: 2;
    .fab{
      margin: 5px 20px;
      font-size: 25px;
      cursor: $pointer-cursor;
    }
    .fab:hover{
      color: $third-color;
    }
  }
  a {
    text-decoration: none;
  }
}

// Privacy
.privacy-page{
  display: flex;
  justify-content: center;
  td {
    padding: 0 7px 0 4px;
    align-items: center;
  }
}

//
.terms-page{
  display: flex;
  justify-content: center;
}

.order-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .main-part{
    display: flex;
  }
.order{
  width: 600px;
  height: 400px;
  background: $second-color;
  .top{
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 50px;
    background: $dark-color;
    color: $second-color;
    font-weight: 400;
  }
}
.strong{
  font-weight: 300;
}
.first, .second{
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 50%;
  padding: 5px;
}
}

@import "./responsiveness.scss";

