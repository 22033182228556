@import "./variables.scss";

.home-page, .home-page-phone{
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background: black;
  // background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1668006967/Nu%20Hippies/Backgrounds/ce6e76ce6cb55fedae6d2b2c126309e3-removebg-preview_miv1fd.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 60px;
  .home-title-wrapper{
    margin-top: 0px;
  }
  h1{
    font-size: 80px;
    color: white;
    margin-top: 10px;
    font-family: $logo-font;
  }

.eye-gif{
  position: absolute;
  top: -30px;
  right: 20px;
}

.home-background-image{
  // width: 300px;
  // height: 560px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -187.5px;
  margin-top: -247px;
  transition: 1.5s linear;
}

.all-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.all {
  display: flex;
  position: absolute;
  top: 50%;
  margin-top: -62px;
  perspective: 10px;
  height: 120px;
  transform: perspective(300px) rotateX(20deg);
  will-change: perspective;
  perspective-origin: center center;
  transition: all 1.3s ease-out;
  justify-content: center;
  transform-style: preserve-3d;
}
.all:hover {
  perspective: 1000px;
  transition: all 1.3s ease-in;
  transform: perspective(10000px) rotateX(0deg);
  .text {
    opacity: 1;
  }
  & > a {
    visibility: visible;
    transition-delay: 0s;
  }
  .explainer {
    visibility: hidden;
  }
}

.home-menu-item {
  width: 120px;
  height: 120px;
  transform-style: preserve-3d;
  border-radius: 50%;
  // border: 1px solid #fff;
  box-shadow: 0 0 20px 5px rgba(809, 809, 17, 0.372);
  visibility: hidden;
  transition-delay: 1.3s;
  position: relative;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f0c350;
  cursor: pointer;
  background-blend-mode: color-burn;
  
  &:hover {
    box-shadow: 0 0 30px 10px $third-color;
    background-color: rgb(220, 253, 255);
  }
}
.text {
  transform: translateY(30px);
  transition: all .3s ease;
  font-size: 25px;
  top: 190px;
  position: absolute;
  margin: 0 auto;
  will-change: transform;
  color: black;
  background: white;
  padding: 8px 20px;
  border-radius: 20px;
  text-shadow: 0px 0px 10px rgba(82,193,199,0.99);
}
.hidden-text{
  visibility: hidden;
}
.lefter {
  transform: translateX(-60px) translateZ(-50px) rotateY(-10deg);
  background-image: url(https://cdn3.iconfinder.com/data/icons/other-icons/48/organization-512.png);
}
.left {
  transform: translateX(-30px) translateZ(-25px) rotateY(-5deg);
  background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654627203/Nu%20Hippies/icons/nu-hippies-shop_sjaopo.png);
}
.center {
  border-radius: 50%;
  background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654624433/Nu%20Hippies/icons/second-hand_qitygo.png);
}
.right {
  transform: translateX(30px) translateZ(-25px) rotateY(5deg);
  background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654625675/Nu%20Hippies/icons/forum_edavyj.png);
}
.righter {
  transform: translateX(60px) translateZ(-50px) rotateY(10deg);
  background-image: url(https://cdn3.iconfinder.com/data/icons/other-icons/48/search-512.png);
}
.explainer {
  font-weight: 300;
  font-size: 2rem;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 120px;
  visibility: visible;
  background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1668021741/Nu%20Hippies/icons/nu-hippies-white-logo_txifvd_wnlnt5.png), radial-gradient(circle at center, black, black);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  text-shadow: 0 0 10px rgba(255, 255, 255, .8); 
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid $second-color;
  animation: 4s shrinking-logo linear infinite;
  transform-origin: left;
  box-shadow: 0 0 20px 5px rgba(809, 809, 17, 0.372);
}

@keyframes shrinking-logo {
  0%{
    box-shadow: 0 0 20px 0px rgba(809, 809, 17, 0.372);
  }
  10%{
    box-shadow: 0 0 20px 5px rgba(809, 809, 17, 0.372);

  }
  20%{
    box-shadow: 0 0 20px 10px rgba(809, 809, 17, 0.372);
  }
  30%{
    box-shadow: 0 0 20px 15px rgba(809, 809, 17, 0.372);
  }
  40%{
    box-shadow: 0 0 20px 20px rgba(809, 809, 17, 0.372);
  }
  50%{
    box-shadow: 0 0 20px 25px rgba(809, 809, 17, 0.372);
  }
  60%{
    box-shadow: 0 0 20px 20px rgba(809, 809, 17, 0.372);
  }
  70%{
    box-shadow: 0 0 20px 15px rgba(809, 809, 17, 0.372);

  }
  80%{
    box-shadow: 0 0 20px 10px rgba(809, 809, 17, 0.372);
  }
  90%{
    box-shadow: 0 0 20px 5px rgba(809, 809, 17, 0.372);
  }
  100%{
    box-shadow: 0 0 20px 0px rgba(809, 809, 17, 0.372);
  }
}
}
// Is Touch Screen or max 600px width

.home-page-phone{
  overflow-x: hidden;
  .home-background-phone{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -274.75px;
    width: 300px;
    height: 533px;
    transition: .5s linear;
    animation: astronauts 60s linear infinite;
  }
  h1{
    font-size: 30px;
  }
  .home-title-wrapper{
    top: 25px;
  }
  .header{
    margin-top: -20px;
  }
  .dynamic-txts{
    li{
      font-size: 25px;
    }
    li span::after{
      margin-top: 25px;
    }
  }
  .all{
    height: 130px;
    margin-top: -70px;
    a{
      text-decoration: none;
    }
  }

  .explainer{
    width: 130px;
    height: 130px;
    z-index: 2;
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1668021741/Nu%20Hippies/icons/nu-hippies-white-logo_txifvd_wnlnt5.png);
  }
  .text{
    visibility: hidden;
  }
  .home-menu-item{
    width: 83px;
    height: 83px;
    transition-delay: 0s;
  }
  .hidden-text{
    visibility: hidden;
    position: relative;
    top: 90px;
    color: white;
    text-align: center;
    font-weight: bold;
  }
}

@keyframes astronauts {
  0%{
    transform: rotate(0deg);
  }
  10%{
    transform: rotate(36deg);

  }
  20%{
    transform: rotate(72deg);
  }
  30%{
    transform: rotate(108deg);
  }
  40%{
    transform: rotate(144deg);
  }
  50%{
    transform: rotate(180deg);
  }
  60%{
    transform: rotate(216deg);
  }
  70%{
    transform: rotate(252deg);

  }
  80%{
    transform: rotate(288deg);
  }
  90%{
    transform: rotate(324deg);
  }
  100%{
    transform: rotate(360deg);
  }
}