@import "./variables.scss";

.second-hand-navbar{
  display: block;
  justify-content: right;
  height: 100px;
  .slogan-header{
      position: absolute;
      right: 20px;
      top: 10px;
      font-family: $logo-font;
      font-size: 25px;
      text-align: center;
  }
  .logo{
    left: 20px;
    margin-left: 0px;
    font-family: $logo-font;
  }
  .search-icon-navbar{
    margin: 95px 30px 0 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

//Unwrap Logo Menu
.menu {
  text-transform: uppercase;
  color: $text-color;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  pointer-events: none;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 5;
  padding: 0 20px;
  .label {
    display: inline-block;
    cursor: pointer;
    pointer-events: all;
  }
  .spacer {
    display: inline-block;
    width: 330px;
    margin-left: -122px;
    margin-top: 253px;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    transform: rotate(90deg);
    z-index: 0;
  }
  .spacer:before {
    content: "";
    position: absolute;
    border-bottom: 1px solid $text-color;
    height: 1px;
    width: 0%;
    transition: width 0.25s ease;
    transition-delay: 0.7s;
  }
  .item {
    position: relative;
    display: inline-block;
    top: 440px;
    opacity: 0;
    transition: opacity 0.5s ease, top 0.5s ease;
    transition-delay: 0;
    
  }
  .one, .two, .three, .four {
    width: 80px;
    height: 80px;
    transform-style: preserve-3d;
    border-radius: 50%;
    // transition: all .3s ease;
    transition-delay: 1s;
    position: relative;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f0c350;
    cursor: pointer;
    background-blend-mode: color-burn;
    margin-bottom: 30px;
    z-index: 4;
    &:hover {
      box-shadow: 0 0 30px 10px $third-color;
      background-color: rgb(220, 253, 255);
      cursor: pointer;
      }
  }
  .one{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654627203/Nu%20Hippies/icons/nu-hippies-shop_sjaopo.png);
  }
  .two{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654624433/Nu%20Hippies/icons/second-hand_qitygo.png);
  }
  .three{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1654625675/Nu%20Hippies/icons/forum_edavyj.png);
  }
  .four{
    background-image: url(https://res.cloudinary.com/nuhippies/image/upload/v1667786561/Nu%20Hippies/icons/home_q5wsmi.png);
  }

  span {
    transition: color 0.5s ease;
  }
  .item:hover span {
    color: #ff0000;
  }
}

.menu:hover {
  pointer-events: all;
  background: $main-background-color;
  height: 430px;
  border: 1px solid $text-color;
  border-top: none;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.logo-menu-text{
  font-size: 15px;
  font-weight: bold;
  border: 1px solid $text-color;
}

.menu:hover .spacer:before {
  width: 100%;
  transition-delay: 0s;
}
.menu:hover .item {
  opacity: 1;
  top: 100px;
}
.item:nth-child(1) {
  transition-delay: 0.45s;
}
.item:nth-child(2) {
  transition-delay: 0.4s;
}
.item:nth-child(3) {
  transition-delay: 0.35s;
}
.item:nth-child(4) {
  transition-delay: 0.3s;
}
.item:nth-child(5) {
  transition-delay: 0.25s;
}
.item:nth-child(6) {
  transition-delay: 0.2s;
}
.item:nth-child(7) {
  transition-delay: 0.15s;
}
.item:nth-child(8) {
  transition-delay: 0.1s;
}
.item:nth-child(9) {
  transition-delay: 0.05s;
}
.item:nth-child(10) {
  transition-delay: 0s;
}
.menu:hover .item:nth-child(1) {
  transition-delay: 0.25s;
}
.menu:hover .item:nth-child(2) {
  transition-delay: 0.3s;
}
.menu:hover .item:nth-child(3) {
  transition-delay: 0.35s;
}
.menu:hover .item:nth-child(4) {
  transition-delay: 0.4s;
}
.menu:hover .item:nth-child(5) {
  transition-delay: 0.45s;
}
.menu:hover .item:nth-child(6) {
  transition-delay: 0.5s;
}
.menu:hover .item:nth-child(7) {
  transition-delay: 0.55s;
}
.menu:hover .item:nth-child(8) {
  transition-delay: 0.6s;
}
.menu:hover .item:nth-child(9) {
  transition-delay: 0.65s;
}
.menu:hover .item:nth-child(10) {
  transition-delay: 0.7s;
}
